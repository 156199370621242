import styled from 'styled-components';

import axios from 'axios';
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import validator from 'validator';

import useAuthentication from '../helpers/authentication';

import { Input, Button } from '../components';
import { toastMessage } from '../components/toast';
import { iconPath } from '../assets/icons';
import GoogleLogin from '../components/googleLogin';

const Container = styled.div`
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: end;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary};
  @media ${(props) => props.theme.devices.md} {
    align-items: center;
  }
`;

const ContainerBox = styled.div`
  background: ${(props) => props.theme.colors.light};
  max-width: 539px;
  width: 100%;
  margin: 0 auto -40px;
  padding: 60px 20px;
  border: 1px solid #d9dde3;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  height: calc(100vh - 350px);
  position: relative;
  @media ${(props) => props.theme.devices.md} {
    padding: 70px 50px;
    border-radius: 25px;
    height: auto;
  }
`;

const LogoContainer = styled.div`
  width: 171px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -50px;
  z-index: 9;
  background: #fff;
  box-shadow: 0px 5.56213px 77.8698px rgba(197, 197, 197, 0.5);
  border-radius: 15px;
  padding: 10px 27px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* @media ${(props) => props.theme.devices.md} {
    width: 220px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 280px;
  }
  @media ${(props) => props.theme.devices.xl} {
    width: 330px;
  } */

  img {
    width: 100%;
    max-width: 80%;
    object-fit: cover;
  }
`;

const TitleContainer = styled.div`
  //margin: 64px 0;
`;

const Title = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.tt1};
  font-size: ${(props) => props.theme.fonts.sm.h3};
  font-weight: 500;
`;

const Heading = styled.p`
  margin: 8px 0;
  color: ${(props) => props.theme.colors.lightGray};
  font-size: ${(props) => props.theme.fonts.sm.h5};
  font-weight: 400;
`;

const FieldContainer = styled.div`
  //margin: 64px 0;
  display: flex;
  flex-direction: column;
  input {
    height: 50px;
  }
`;

const RequireIconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const RequireOuterContainer = styled.div`
  width: 12px;
  margin-right: 5px;
  @media ${(props) => props.theme.devices.md} {
    width: 14px;
  }
  @media ${(props) => props.theme.devices.lg} {
    width: 20px;
    margin-right: 12px;
  }
`;

const TextRequired = styled.p`
  font-size: ${(props) => props.theme.fonts.sm.p};
  font-weight: 500;
  color: #ff5630;
  margin: 0px;
  text-align: center;
  /* @media ${(props) => props.theme.devices.md} {
    font-size: ${(props) => props.theme.fonts.md.t};
  }
  @media ${(props) => props.theme.devices.lg} {
    font-size: ${(props) => props.theme.fonts.lg.t};
  } */
`;

const TextRequiredError = styled(TextRequired)`
  text-align: left;
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
`;

const TabsContainer = styled(Tabs)``;
const TabName = styled(Tab)``;

const InputLabel = styled.label`
  color: #12141d;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 22px;
  display: inline-block;
`;

export default function Login() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { userRegistration, companyDetailsAPI, googleLogin, googleBusiness } = useAuthentication();
  const [errors, setErrors] = useState({
    email: '',
    phone: '',
    loginWith: '',
    loginWithNumber: '',
  });
  const [loginForm, setLoginForm] = useState({
    email: '',
    phone: '',
    loginWith: '',
    loginWithNumber: '',
  });
  const [apiError, setApiError] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [tabSelect, setTabSelect] = useState();
  const [authParam, setAuthParam] = useState();
  const [Loader, setLoader] = useState(true);
  const [newCompanyLogo, setNewCompanyLogo] = useState('');

  const { Logo, RequireIcon } = iconPath;


  const BASE_URL = 'sbads.infy.tv';
  // const BASE_URL = process.env.REACT_APP_API_URL ? 'ads.infy.tv' : 'sbads.infy.tv';

  // const BASE_URL = 'ads.infy.tv';

  // UPDATE LOGIN FORM VALUES ONCHANGE EVENT

  function updateLoginFormValues(key, value) {
    const newLoginForm = { ...loginForm };

    newLoginForm[key] = value;

    setLoginForm(newLoginForm);

    setErrors({
      email: newLoginForm.email?.length > 0 ? '' : errors.email,
      phone: newLoginForm.phone?.length > 0 ? '' : errors.phone,
      loginWith: newLoginForm.loginWith?.length > 0 ? '' : errors.loginWith,
      loginWithNumber: newLoginForm.loginWithNumber?.length > 0 ? '' : errors.loginWithNumber,
    });
  }

  useEffect(() => {
    if (state?.loginTrue) {
      toast.success(toastMessage.signUp);
    }
  }, [state?.loginTrue]);

  // FORM VALIDATION

  function validateLoginForm(e) {
    e.preventDefault();
    let valid = true;
    const newErrors = { ...errors };
    if (state?.email || state?.mobile) {
      if (state?.email && validator.isEmpty(loginForm.email)) {
        valid = false;
        newErrors['email'] = 'Email is required';
      } else if (state?.email && !validator.isEmail(loginForm.email)) {
        valid = false;
        newErrors['email'] = 'Please Enter Valid Email';
      }
      if (state?.mobile && validator.isEmpty(loginForm.loginWithNumber)) {
        valid = false;
        newErrors['loginWithNumber'] = 'Phone Number is required';
      }
    } else {
      if (tabSelect === 2 && validator.isEmpty(loginForm.phone)) {
        valid = false;
        newErrors['phone'] = 'Please Enter Phone Number';
      }
      if (tabSelect !== 2 && validator.isEmpty(loginForm.loginWith)) {
        valid = false;
        newErrors['loginWith'] = 'Please Enter Email';
      } else if (tabSelect !== 2 && !validator.isEmail(loginForm.loginWith)) {
        valid = false;
        newErrors['loginWith'] = 'Please Enter Valid Email';
      }
    }

    const email = {
      email: loginForm.loginWith.toLowerCase(),
    };
    const mobile = {
      mobile: loginForm.phone,
    };

    if (valid) {
      setButtonLoader(true);
      userRegistration(
        state?.email
          ? {
              email: loginForm.email.toLowerCase(),
              mobile: state?.username,
              new_otp: localStorage.getItem('newOtp'),
            }
          : state?.mobile
          ? {
              mobile: loginForm?.loginWithNumber,
              email: state?.username.toLowerCase(),
              new_otp: localStorage.getItem('newOtp') ?? state?.newOtp,
            }
          : tabSelect === 2
          ? mobile
          : email,
      ).then(({ code, data, success, message }) => {
        if (success && code === 200) {
          setButtonLoader(false);
          const username = state?.email
            ? loginForm?.email.toLowerCase()
            : state?.mobile
            ? loginForm?.loginWithNumber
            : tabSelect === 2
            ? loginForm.phone
            : loginForm.loginWith.toLowerCase();

          const mailOtp =
            loginForm?.email.includes('@') || loginForm.loginWith.includes('@') ? true : false;

          const redirectUrl = `http://${authParam}.${BASE_URL}/verify-otp?username=${username}&mailOtp=${mailOtp}`;
          window.location.href = redirectUrl;
          // navigate(`/verify-otp`, {
          //   state: {
          //     username: state?.email
          //       ? loginForm?.email.toLowerCase()
          //       : state?.mobile
          //       ? loginForm?.loginWithNumber
          //       : tabSelect === 2
          //       ? loginForm.phone
          //       : loginForm.loginWith.toLowerCase(),
          //     mailOtp:
          //       loginForm?.email.includes('@') || loginForm.loginWith.includes('@') ? true : false,
          //   },
          // });
        } else {
          setButtonLoader(false);
          setApiError(message);
          console.log(message);
        }
      });
    } else {
      setErrors(newErrors);
      return false;
    }
  }

  useEffect(() => {
    getCountryCode();
  }, []);

  // GET COUNTRY CODE BY USING IP

  async function getCountryCode() {
    const getCode = await axios.get('https://ipinfo.io/json/?token=90c41b84ca045c');
    setCountryCode(getCode?.data?.country);
  }

  const urlParams = new URLSearchParams(window.location.search);
  const scope = urlParams.get('scope');
  const scopeCode = urlParams.get('code');
  const scopeState = urlParams.get('state');
  const redirectURL = urlParams.get('redirect');

  // Retrieve the ap from the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authParams = urlParams.get('ap');

    if (authParams) {
      const cleanedAuthParams = authParams.replace(/\\/g, '').replace(/"/g, '');
      setLoader(true);
      companyDetailsAPI(cleanedAuthParams).then((data) => {
        if (data.success === true) {
          setNewCompanyLogo(data?.data?.thumb);
          localStorage.setItem('newCompanyLogo', data?.data?.thumb);
          if (cleanedAuthParams) {
            localStorage.setItem('authParams', true);
          }
          setLoader(false);
        } else {
          navigate(`/login?ap=${authParams}`);
          toast.error('Subdomain is invalid.');
        }
      });
      setAuthParam(cleanedAuthParams);
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem('scopeCode') &&
      localStorage.getItem('scope') &&
      localStorage.getItem('isAuthenticated') &&
      localStorage.getItem('loginToken') &&
      localStorage.getItem('business') === 'true'
    ) {
      navigate('/dashboard');
    } else if (
      localStorage.getItem('scopeCode') &&
      localStorage.getItem('scope') &&
      localStorage.getItem('isAuthenticated') &&
      localStorage.getItem('loginToken') &&
      localStorage.getItem('business') === 'false'
    ) {
      navigate('/select-business');
    }
  }, [localStorage]);

  useEffect(() => {
    if (scope && scopeCode) {
      localStorage.setItem('scope', scope);
      localStorage.setItem('scopeCode', scopeCode);
      const payload = {
        code: scopeCode,

        redirect_uri: `https://sbads.infy.tv`,

        scope: scope,
        state: scopeState,
      };
      setLoader(true);
      googleLogin(payload).then(({ code, data, success, message }) => {
        if (success && code === 200) {
          localStorage.setItem('googleOauth', true);
          localStorage.setItem('isAuthenticated', true);
          localStorage.setItem('loginToken', data?.token?.access_token);
          localStorage.setItem('expireToken', data?.token?.expires_in);
          localStorage.setItem('loginTime', new Date());
          localStorage.setItem('business', data?.business_id ? true : false);
          if (data?.business_id) {
            navigate('/dashboard');
            window.location.reload();
            setLoader(false);
          } else if (data?.is_new_user === false) {
            googleBusiness()
              .then((data) => {
                if (data?.data.length === 0) {
                  navigate('/search-business');
                  localStorage.setItem('businessData', false);
                  setLoader(false);
                } else {
                  navigate('/select-business');
                  localStorage.setItem('businessData', true);
                  setLoader(false);
                }
              })
              .catch((error) => {
                toast.error(error);
              });
          } else if (data?.business_id === false && data?.is_new_user === true) {
            navigate('/login', {
              state: {
                mobile: true,
                isNumber: true,
                username: data?.email,
                newOtp: data?.new_otp,
              },
            });
            setLoader(false);
          }
        } else {
          // console.log(message);
          // navigate(`/login?ap=${scopeState}`);
          setLoader(false);
          toast.error(message);
          setTimeout(() => {
            const redirectUrl = `http://${BASE_URL}/login?ap=${scopeState}`;
            window.location.href = redirectUrl;
          }, 2000);}
      });
    }
  }, [scope, scopeCode, scopeState]);

  // If not logged in it will auto redirect to
  const [domainStatus, setDomainStatus] = useState(false);
  useEffect(() => {
    if(redirectURL && !localStorage.getItem("isAuthenticated")){
      localStorage.clear();
    }
    const hostnameParts = window.location.hostname.split('.');
    const subdomain = hostnameParts.length > 1 ? hostnameParts[0] : '';
    

    if (localStorage.length === 0 && subdomain !== 'sbads' && !domainStatus) {

      const loginUrl = `http://${BASE_URL}/login?ap=${subdomain}`;
      const currentUrl = window.location.href;

      if (currentUrl === loginUrl) {
        return;
      } else if (currentUrl.includes(`login?ap=${subdomain}`)) {
        setDomainStatus(true);
      } else {
        window.location.href = loginUrl;
      }
    }
  }, [redirectURL]);

  const scopes =
    'openid email profile https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/business.manage';

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: scopes,
    state: authParam,
    onSuccess: async (codeResponse) => {
      if (codeResponse) {
        // Redirect to the subdomain
        const redirectUrl = `http://${authParam}.${BASE_URL}/login?scope=${codeResponse.scope}&code=${codeResponse.code}&state=${authParam}`;
        window.location.href = redirectUrl;
      }
      setLoader(true);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  useEffect(() => {
    if (window.location.origin === `http://sbads.infy.tv`) {
      setLoader(false);
    }
  }, []);
  

  return (
    <Container>
      {Loader ? (
        <div className="loader"></div>
      ) : (
        <ContainerBox>
          <LogoContainer>{authParam ? <img src={newCompanyLogo} /> : <Logo />}</LogoContainer>
          <TitleContainer>
            <Title>Welcome!</Title>
            <Heading>We are Infy TV. Sign In to Continue.</Heading>
            <form onSubmit={validateLoginForm} style={{ marginBottom: '20px' }}>
              {!state?.email && !state?.mobile && (
                <TabsContainer
                  activeTab="1"
                  className=""
                  ulClassName=""
                  activityClassName="bg-success"
                  onClick={(event, tab) => setTabSelect(tab)}
                >
                  <TabName title="Email" className="mr-3">
                    <InputLabel>Email</InputLabel>
                    <Input
                      placeholder="Enter Email Address"
                      type="text"
                      inputmode="email"
                      name="loginWith"
                      kind="full"
                      errorList={errors?.loginWith?.length > 0 && true}
                      onChange={(e) => {
                        setApiError('');
                        updateLoginFormValues(e.target.name, e.target.value);
                      }}
                    />
                    {errors.loginWith.length > 0 && (
                      <RequireIconContainer>
                        <RequireOuterContainer>
                          <RequireIcon />
                        </RequireOuterContainer>
                        <TextRequired>{errors.loginWith}</TextRequired>
                      </RequireIconContainer>
                    )}
                    <ButtonContainer>
                      <Button
                        type="submit"
                        buttonText="Continue"
                        buttonLoader={buttonLoader}
                        disabled={buttonLoader}
                      />
                    </ButtonContainer>
                  </TabName>
                  <TabName title="Phone Number" className="mr-3">
                    <InputLabel>Phone Number</InputLabel>
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      placeholder="Enter phone number"
                      defaultCountry={countryCode}
                      onChange={(e) => {
                        updateLoginFormValues('phone', e);
                      }}
                      defaultValue={loginForm.phone}
                      value={loginForm.phone}
                      autoFocus={true}
                      className={
                        errors?.phone?.length > 0 && true ? 'activeErrorPhone' : 'activePhone'
                      }
                    />
                    {errors.phone.length > 0 && (
                      <RequireIconContainer>
                        <RequireOuterContainer>
                          <RequireIcon />
                        </RequireOuterContainer>
                        <TextRequired>{errors.phone}</TextRequired>
                      </RequireIconContainer>
                    )}
                    <ButtonContainer>
                      <Button
                        type="submit"
                        buttonText="Continue"
                        buttonLoader={buttonLoader}
                        disabled={buttonLoader}
                      />
                    </ButtonContainer>
                  </TabName>
                </TabsContainer>
              )}
              <FieldContainer>
                <>
                  {state?.email ? (
                    <>
                      <Input
                        placeholder="Enter Email Address"
                        type="text"
                        inputmode="email"
                        name="email"
                        kind="full"
                        errorList={errors?.email?.length > 0 && true}
                        onChange={(e) => {
                          setApiError('');
                          updateLoginFormValues(e.target.name, e.target.value);
                        }}
                      />
                      {errors.email.length > 0 && (
                        <RequireIconContainer>
                          <RequireOuterContainer>
                            <RequireIcon />
                          </RequireOuterContainer>
                          <TextRequired>{errors.email}</TextRequired>
                        </RequireIconContainer>
                      )}
                    </>
                  ) : state?.mobile ? (
                    <>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        placeholder="Enter phone number"
                        defaultCountry={countryCode}
                        onChange={(e) => updateLoginFormValues('loginWithNumber', e)}
                        defaultValue={loginForm.loginWithNumber}
                        value={loginForm.loginWithNumber}
                        autoFocus={true}
                        className={
                          errors?.loginWithNumber?.length > 0 && true
                            ? 'activeErrorPhone'
                            : 'activePhone'
                        }
                      />
                      {errors.loginWithNumber.length > 0 && (
                        <RequireIconContainer>
                          <RequireOuterContainer>
                            <RequireIcon />
                          </RequireOuterContainer>
                          <TextRequired>{errors.loginWithNumber}</TextRequired>
                        </RequireIconContainer>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
                {apiError && <TextRequiredError>{apiError}</TextRequiredError>}
                {(state?.email || state?.mobile) && (
                  <ButtonContainer>
                    <Button
                      type="submit"
                      buttonText="Continue"
                      buttonLoader={buttonLoader}
                      disabled={buttonLoader}
                    />
                  </ButtonContainer>
                )}
              </FieldContainer>
            </form>
            {(tabSelect !== 2 && state?.isNumber !== true) && (
              <Button
                kind={'border'}
                type="button"
                buttonText="Continue with Google"
                img={'https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'}
                onClick={() => handleGoogleLogin()}
              />
            )}
          </TitleContainer>
        </ContainerBox>
      )}
    </Container>
  );
}
